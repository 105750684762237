require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

var jQuery = require("jquery")
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require('admin-lte');
require('admin-lte/dist/js/adminlte.min.js');

require("trix")
require("@rails/actiontext")

require('jquery-maskmoney/dist/jquery.maskMoney.min.js')
require('bootstrap-datepicker/js/bootstrap-datepicker')
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.pt-BR')
require('admin-lte/plugins/bootstrap/js/bootstrap.min.js')
require('admin-lte/plugins/daterangepicker')
require('admin-lte/plugins/bootstrap/js/bootstrap.min')

require('@nathanvda/cocoon')


import "@fortawesome/fontawesome-free/js/all";
import "../others/sweet-alert-confirm";
import 'select2/dist/js/select2'
import bsCustomFileInput from 'bs-custom-file-input/dist/bs-custom-file-input.min.js'
import "chartkick/chart.js"

import moment from "moment"

import '../../assets/stylesheets/application.scss'


document.addEventListener("turbolinks:load", () => {
    var activePage = stripTrailingSlash(window.location.pathname.split("/")[1]);

    function stripTrailingSlash(str) {
        if (str.substr(-1) == "/") {
            return str.substr(0, str.length - 1);
        }
        return str;
    }

    $(function() {
        bsCustomFileInput.init()

        moment.locale('pt-BR')
        $('.date-rangepicker').daterangepicker({
            autoUpdateInput: false,
            "locale": {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Aplicar",
                "cancelLabel": "Cancelar",
                "fromLabel": "De",
                "toLabel": "Até",
                "customRangeLabel": "Personalizado"
            }
        })
    
        $('.date-rangepicker').on('apply.daterangepicker', function(_ev, picker) {
            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        });
    
        $('.date-rangepicker').on('cancel.daterangepicker', function(_ev, _picker) {
            $(this).val('');
        });
    
        $('.datepicker').datepicker({
            language: 'pt-BR',
            autoclose: true,
            format: "dd/mm/yyyy",
            orientation: 'auto bottom'
        })

        $(".select2").select2({
            "language": "pt-BR"
        })
        
        $('.master-menu').each((_index, element) => {
            if ($(element).find('.sub-menu').length === 0) $(element).hide()
        })

        $('a.nav-link.active').parent().first().addClass('active')
        $('a.nav-link.active').parent().first().parent().first().addClass('menu-open')
        $('a.nav-link.active').parent().first().parent().first().show()
        $('a.nav-link.active').parent().first().parent().first().siblings('a').addClass('active')


        $('.password-toggle').on('click', (e) => {
            const input = $(e.currentTarget).siblings()
            
            if (input.prop('type') == 'password') {
                input.prop('type', 'text')
                $(e.currentTarget).find('.fa-eye').removeClass('fa-eye').addClass('fa-eye-slash')
            } else {
                input.prop('type', 'password')
                $(e.currentTarget).find('.fa-eye-slash').removeClass('fa-eye-slash').addClass('fa-eye')
            }
        })

        $('[data-toggle="tooltip"]').tooltip()
    });
});
