import Rails from "@rails/ujs";
import Swal from 'sweetalert2'

const confirmed = (element, result) => {
    if (result.value) {
        // User clicked confirm button
        element.removeAttribute('data-confirm')
        element.click()
    }
}

// Display the confirmation dialog
const showConfirmationDialog = (element) => {
    const message = element.getAttribute('data-confirm')
    const text = element.getAttribute('data-text')
    const data_type = element.getAttribute('data-type');

    if (data_type === 'delete') {
        Swal.fire({
            title: message,
            text: text,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim, excluir!'
        }).then((result) => {
            confirmed(element, result);
        })
    } else if (data_type === 'confirm') {
        Swal.fire({
            title: message,
            text: text,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            customClass: {
                actions: 'd-flex flex-row-reverse justify-content-around'
            }
        }).then((result) => {
            confirmed(element, result);
        })
    } else if (data_type === 'observation') {
        Swal.fire({
            title: message,
            text: text,
            input: 'textarea',
            inputLabel: 'Observações*',
            inputPlaceholder: 'Observações.',
            inputAttributes: {
                maxlength: 200,
            },
            inputValidator: (result) => {
                return !result && 'Campo obrigatório'
            },
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Salvar',
            customClass: {
                actions: 'd-flex flex-row-reverse justify-content-around'
            }
            // preConfirm: (observations) => {
            //     return fetch(`${element.href}?observation=${observations}`, {method: 'PUT'})
            //     .then(response => {
            //         if (!response.ok) {
            //             debugger
            //         throw new Error(response.message)
            //         }
            //         return response.json()
            //     })
            //     .catch(error => {
            //         Swal.showValidationMessage(
            //         `${error}`
            //         )
            //     })
            // },
        }).then((result) => {
            element.href = `${element.href}?observations=${result.value}`
            confirmed(element, result);
        })
    } else {
        Swal.fire({
            title: message,
            text: text,
            icon: data_type,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok!'
        })
    }

}

const allowAction = (element) => {
    if (element.getAttribute('data-confirm') === null) {
        return true
    }

    showConfirmationDialog(element)
    return false
}

function handleConfirm(element) {
    if (!allowAction(this)) {
        Rails.stopEverything(element)
    }
}

Rails.delegate(document, 'a[data-confirm]', 'click', handleConfirm)
Rails.delegate(document, 'input[data-confirm]', 'click', handleConfirm)
Rails.delegate(document, 'input[data-confirm]', 'submit', handleConfirm)

// function navClick(element) {
//     console.log(element);
// }

// Rails.delegate(document, '.nav-item', 'click', navClick)